import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../../images/logo.png";
const data = require("../../data/categories.json");

function HeaderNav() {
  var categories = data.categories;

  return (
    <Navbar
      id="headerNavigation"
      collapseOnSelect
      bg="light"
      variant="light"
      expand="lg"
      className="header-shadow"
    >
      <Navbar.Brand href="/">
        <img
          src={logo}
          className="d-inline-block"
          alt="Safe Weld Traders logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="header-navbar-nav" />
      <Navbar.Collapse id="header-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <NavDropdown title="Products">
            {categories.map((catNames) => (
              <NavDropdown.Item
                key={
                  new Date().getTime().toString() +
                  Math.floor(Math.random() * 1000000)
                }
                href="/products"
              >
                {catNames.categoryName}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HeaderNav;
