import React from "react";
function FooterNav() {
  return (
    <footer className="py-5 text-center">
      &copy; 2001 - 2020 Safe Weld Traders
      <nav className="pt-3">
        <a href="/">Home</a>
        <a href="/products" className="px-3">
          Products
        </a>
        <a href="/about" className="pr-3">
          About
        </a>
        <a href="/contact">Contact</a>
      </nav>
    </footer>
  );
}

export default FooterNav;
