import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import {
  Card,
  CardColumns,
  Container,
  Form,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
const data = require("../../data/categories.json");

function ProductsPage() {
  var categories = data.categories;
  const [search, setSearch] = useState(null);
  const [menuClickedData, setMenuClickedData] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const searchSpace = (event) => {
    let search = event.target.value;
    setSearch(search);
    setMenuClickedData(null);
  };

  function filterProds(menuClickedId) {
    setExpanded(false);
    let menuFilterData;
    for (var i = 0; i < categories.length; i++) {
      for (var j = 0; j < categories[i].sub_categories.length; j++) {
        if (categories[i].sub_categories[j]._id === menuClickedId) {
          menuFilterData = categories[i].sub_categories[j];
        }
      }
    }
    setMenuClickedData(menuFilterData);
  }

  return (
    <>
      <Navbar
        id="productsNavigation"
        bg="light"
        variant="light"
        expand="md"
        className="mt-1 mb-5 py-4"
        expanded={expanded}
      >
        <Navbar.Brand className="d-inline-block d-md-none">
          Major Categories
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="prod-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="prod-navbar-nav">
          <Nav className="">
            <Nav.Link href="/products">All</Nav.Link>
            {categories.map((catNames) => (
              <NavDropdown
                key={
                  new Date().getTime().toString() +
                  Math.floor(Math.random() * 1000000)
                }
                title={catNames.categoryName}
                id={catNames._id}
              >
                {catNames.sub_categories.map((subCat) => (
                  <NavDropdown.Item
                    key={
                      new Date().getTime().toString() +
                      Math.floor(Math.random() * 100000)
                    }
                    onClick={() => filterProds(subCat._id)}
                  >
                    {subCat.subCategoryName}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            ))}
          </Nav>
          <Form inline className="position-relative mt-3 mt-md-0 w-100">
            <FontAwesomeIcon icon={faSearch} className="productsSearchIcon" />
            <FormControl
              id="searchfield"
              type="text"
              placeholder="Search products..."
              onChange={(e) => searchSpace(e)}
              className="ml-0 ml-md-3 w-100"
            />
          </Form>
        </Navbar.Collapse>
      </Navbar>

      <Container fluid id="productPage">
        <h2 className="text-center mt-5 mb-2">Our Products</h2>
        <CardColumns className="py-4">
          {menuClickedData === null ? (
            <>
              {categories.map((catNames) => (
                <Fragment
                  key={
                    new Date().getTime().toString() +
                    Math.floor(Math.random() * 1000000)
                  }
                >
                  {catNames.sub_categories.map((subCat) => (
                    <Fragment
                      key={
                        new Date().getTime().toString() +
                        Math.floor(Math.random() * 1000000)
                      }
                    >
                      {subCat.items
                        // eslint-disable-next-line
                        .filter((item) => {
                          if (search == null) return item;
                          else if (
                            item.itemName
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item) => {
                          return (
                            <Card
                              className="mb-4 p-3"
                              key={
                                new Date().getTime().toString() +
                                Math.floor(Math.random() * 1000000)
                              }
                            >
                              <div className="prodBorder">
                                <Card.Img
                                  src={`${item.image.url}`}
                                  className="d-block w-50 mx-auto my-3"
                                />
                                <Card.Title className="py-3 text-center">
                                  {item.itemName}
                                </Card.Title>
                                <Card.Subtitle className="pb-3 text-center text-muted">
                                  {item.itemDesc}
                                </Card.Subtitle>
                              </div>
                            </Card>
                          );
                        })}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </>
          ) : (
            menuClickedData.items.map((item) => (
              <Card
                className="mb-4 p-3"
                key={
                  new Date().getTime().toString() +
                  Math.floor(Math.random() * 1000000)
                }
              >
                <div className="prodBorder">
                  <Card.Img
                    src={`${item.image.url}`}
                    className="d-block w-50 mx-auto my-3"
                  />
                  <Card.Title className="py-3 text-center">
                    {item.itemName}
                  </Card.Title>
                  <Card.Subtitle className="pb-3 text-center text-muted">
                    {item.itemDesc}
                  </Card.Subtitle>
                </div>
              </Card>
            ))
          )}
        </CardColumns>
      </Container>
    </>
  );
}

export default ProductsPage;
