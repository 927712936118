import React from "react";
import {
  Button,
  Card,
  CardColumns,
  Col,
  Container,
  Image,
  Jumbotron,
  Row,
} from "react-bootstrap";
import { default as HeroImageMobile } from "../../images/home-banner.png";
const data = require("../../data/categories.json");
const brandLogos = require("../../data/brands.json");

function HomePage() {
  var categories = data.categories;
  var brands = brandLogos.brands;

  return (
    <>
      <Jumbotron fluid className="mb-0">
        <picture>
          <img src={HeroImageMobile} alt="" width="100%" />
        </picture>
      </Jumbotron>

      <Container fluid className="py-5 categories--home">
        <h2 className="text-center pb-3">Major Categories</h2>
        <CardColumns className="py-4">
          {categories.map((catNames, i) => (
            <Card
              className="mb-4"
              key={Math.floor(Math.random() * Math.floor(1))}
            >
              <Card.Header>{catNames.categoryName}</Card.Header>
              <Card.Img
                variant="top"
                src={catNames.categoryImg}
                className="d-block w-50 mx-auto my-3"
              />
              <Card.Body>
                <Card.Text>{catNames.categoryDesc}</Card.Text>
              </Card.Body>
              <Card.Footer className="text-right">
                <a href="/products">
                  <Button size="lg" variant="primary">
                    Know More
                  </Button>
                </a>
              </Card.Footer>
            </Card>
          ))}
        </CardColumns>
      </Container>

      <Container fluid className="py-5 brands-home">
        <Row>
          {brands.map((blogo, i) => (
            <Col xs={3} md={2} className="align-self">
              <Image src={blogo.img} className="mb-3" alt="" thumbnail />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default HomePage;
