import {
  faEnvelope,
  faMapMarker,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import whatsapp from "../../images/whatsapp.svg";

function ContactPage() {
  return (
    <Container fluid>
      <h2 className="text-center my-5">Contact Us</h2>
      <Row id="contact-cards" className="my-5">
        <Col md="6" sm="12" className="my-4">
          <Row>
            <Col
              md="3"
              sm="12"
              className="d-flex justify-content-center align-items-center pb-4"
            >
              <FontAwesomeIcon icon={faPhone} size="3x" color="green" />
            </Col>
            <Col md="9" sm="12">
              <Card className="text-center border-none contact-card--1">
                <Card.Body className="card-shadow br-8">
                  <a className=" fs-20 d-block" href="tel:+918041134581">
                    080-41134581
                  </a>
                  <a className=" fs-20" href="tel:+918041237373">
                    080-41237373
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md="6" sm="12" className="my-4">
          <Row>
            <Col
              md="3"
              sm="12"
              className="d-flex justify-content-center align-items-center pb-4"
            >
              <img
                src={whatsapp}
                style={{
                  width: "3.25rem",
                }}
                alt=""
              />
            </Col>
            <Col md="9" sm="12">
              <Card className="text-center border-none contact-card--4">
                <a
                  href="https://api.whatsapp.com/send?phone=919448378652"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card.Body className="card-shadow br-8">
                    <p className="mt-5 fs-20">Get in touch on WhatsApp</p>
                  </Card.Body>
                </a>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md="6" sm="12" className="my-4">
          <Row>
            <Col
              md="3"
              sm="12"
              className="d-flex justify-content-center align-items-center pb-4"
            >
              <FontAwesomeIcon icon={faMapMarker} size="3x" color="red" />
            </Col>
            <Col md="9" sm="12">
              <Card className="text-center border-none contact-card--2">
                <a
                  href="https://goo.gl/maps/xfWYZBZiVfDnhtn47"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card.Body className="card-shadow br-8">
                    <p className="">
                      Safe Weld Traders, 1/7 Naaz Complex, B, Osman Khan Rd,
                      Behind N. R. Rd, Bangalore - 560 002.
                    </p>
                  </Card.Body>
                </a>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md="6" sm="12" className="my-4">
          <Row className="mb-4">
            <Col
              md="3"
              sm="12"
              className="d-flex justify-content-center align-items-center pb-4"
            >
              <FontAwesomeIcon icon={faEnvelope} size="3x" color="blue" />
            </Col>
            <Col md="9" sm="12">
              <Card className="text-center border-none contact-card--3">
                <a href="mailto:info@safeweld.in?cc=safeweld.traders@gmail.com">
                  <Card.Body className="card-shadow br-8">
                    <p className="mt-5 fs-20">info@safeweld.in</p>
                  </Card.Body>
                </a>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.0086963730912!2d77.58199647995171!3d12.962946981595943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e0e25c7f25%3A0x83dbac59fbf19e87!2sNaaz%20Complex%2C%20Osman%20Khan%20Rd%2C%20Kumbarpet%2C%20Kalasipalya%2C%20Bengaluru%2C%20Karnataka%20560002!5e0!3m2!1sen!2sin!4v1609421060368!5m2!1sen!2sin"
        className="border-none my-5"
        title="googlemap"
        width="100%"
        height="450"
        frameBorder="0"
        allowFullScreen="true"
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </Container>
  );
}

export default ContactPage;
